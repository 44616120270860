import React from 'react';
import ContactForm from '../components/ContactForm';
import './Contact.css';

const Contact = () => {
    return (
        <div className="contact-container">
            <h1>Contact Us</h1>
            <p>Need something sorted? We're here to help!</p>
            <ContactForm />
            <p className="response-time">
                Please note that we aim to respond within 12 hours, however, please allow up to 48 hours for a response.
            </p>
        </div>
    );
};

export default Contact;

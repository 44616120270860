import React from 'react';
import './Maintenance.css'; // Create this CSS file for styling
import logo from '../assets/logo-new.png'; // Adjust the path to your logo

const Maintenance = () => {
    return (
        <div className="maintenance-container">
            <img src={logo} alt="Logo" className="maintenance-logo" />
            <h1>We'll be back soon!</h1>
            <p>We are currently performing maintenance. Please check back later.</p>
            <div className="icon">
                <i className="fa fa-cogs"></i>
            </div>
        </div>
    );
};

export default Maintenance;

import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Header.css';
import { useAuth } from '../context/AuthContext';
import { Helmet } from 'react-helmet';
import logo from '../assets/logo-new.png';
import AutomatedBrowserCheck from './AutomatedBrowserCheck';

const Header = () => {
    const { isAuthenticated, userRole, logout } = useAuth();
    const location = useLocation();
    const [menuOpen, setMenuOpen] = useState(false);

    const isActive = (path) => location.pathname === path ? 'active' : '';

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <header className="header">
            <AutomatedBrowserCheck />
            <Helmet>
                <script src="https://kit.fontawesome.com/81b7a177ab.js" crossOrigin="anonymous"></script>
            </Helmet>
            <div className="header-top">
                <div className="logo">
                    <Link to="/">
                        <img src={logo} alt="Time at the Bar Logo" />
                    </Link>
                    {/* <h1>TIME AT THE BAR</h1> */}
                </div>
                {isAuthenticated && (
                    <div className="user-info">
                        {/* {userRole === "pubgoer" && (
                            <span className="user-role">Pub-Goer</span>
                        )}

                        {userRole === "host" && (
                            <span className="user-role">Host</span>
                        )} */}
                    </div>
                )}
                <nav className={`header-nav ${menuOpen ? 'open' : ''}`}>
                    <ul className="nav-list">
                        <li className={isActive('/') ? 'active' : ''}><Link to="/" onClick={toggleMenu}>Home</Link></li>
                        <li className="dropdown">
                            <span className="dropdown-toggle">Business</span>
                            <ul className="dropdown-menu">
                                <li className={isActive('/about') ? 'active' : ''}><Link to="/about" onClick={toggleMenu}>About</Link></li>
                                <li className={isActive('/contact') ? 'active' : ''}><Link to="/contact" onClick={toggleMenu}>Contact</Link></li>
                            </ul>
                        </li>
                        <li className={isActive('/find-a-pub') ? 'active' : ''}><Link to="/find-a-pub" onClick={toggleMenu}>Find a Pub</Link></li>
                        {isAuthenticated ? (
                            <>
                                {userRole === "pubgoer" && (
                                    <>
                                        <li className={isActive('/favourites') ? 'active' : ''}><Link to="/favourites" onClick={toggleMenu}>Favourites</Link></li>
                                    </>
                                )}
                                {userRole === "host" && (
                                    <li className={isActive('/dashboard') ? 'active' : ''}><Link to="/dashboard" onClick={toggleMenu}>Dashboard</Link></li>
                                )}
                                <li className="dropdown">
                                    <span className="dropdown-toggle">Account</span>
                                    <ul className="dropdown-menu">
                                        <li className={isActive('/my-account') ? 'active' : ''}><Link to="/my-account" onClick={toggleMenu}>My Account</Link></li>
                                        <li onClick={logout}><Link to="#">Logout</Link></li>
                                    </ul>
                                </li>
                            </>
                        ) : (
                            <>
                                <li className={isActive('/login') ? 'active' : ''}><Link to="/login" onClick={toggleMenu}>Login</Link></li>
                            </>
                        )}
                    </ul>
                </nav>
                <button className="burger-menu" onClick={toggleMenu}>
                    <i className="fa fa-bars"></i>
                </button>
            </div>
        </header>
    );
};

export default Header;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Favourites.css';
import Loading from '../components/Loading';
import defaultImage from '../assets/default.png';

// Import feature images
import beerGardenImage from '../assets/icons/standard/beerGarden.png';
import beerGardenPrefImage from '../assets/icons/animation/beerGarden.png';
import dogFriendlyImage from '../assets/icons/standard/dogFriendly.png';
import dogFriendlyPrefImage from '../assets/icons/animation/dogFriendly.png';
import liveMusicImage from '../assets/icons/standard/liveMusic.png';
import liveMusicPrefImage from '../assets/icons/animation/liveMusic.png';
import poolTableImage from '../assets/icons/standard/poolTable.png';
import poolTablePrefImage from '../assets/icons/animation/poolTable.png';
import pubFoodImage from '../assets/icons/standard/pubFood.png';
import pubFoodPrefImage from '../assets/icons/animation/pubFood.png';
import realAleImage from '../assets/icons/standard/realAle.png';
import realAlePrefImage from '../assets/icons/animation/realAle.png';
import dartsImage from '../assets/icons/standard/darts.png';
import dartsPrefImage from '../assets/icons/animation/darts.png';
import disabledAccessImage from '../assets/icons/standard/disabledAccess.png';
import disabledAccessPrefImage from '../assets/icons/animation/disabledAccess.png';
import pubQuizImage from '../assets/icons/standard/pubQuiz.png';
import pubQuizPrefImage from '../assets/icons/animation/pubQuiz.png';
import sundayRoastImage from '../assets/icons/standard/sundayRoast.png';
import sundayRoastPrefImage from '../assets/icons/animation/sundayRoast.png';
import btSportsImage from '../assets/icons/standard/btSports.png';
import btSportsPrefImage from '../assets/icons/animation/btSports.png';
import skySportsImage from '../assets/icons/standard/skySports.png';
import skySportsPrefImage from '../assets/icons/animation/skySports.png';
import parkingImage from '../assets/icons/standard/parking.png';
import parkingPrefImage from '../assets/icons/animation/parking.png';
import accommodationImage from '../assets/icons/standard/accommodation.png';
import accommodationPrefImage from '../assets/icons/animation/accommodation.png';
import nearCampsiteImage from '../assets/icons/standard/nearCampsite.png';
import nearCampsitePrefImage from '../assets/icons/animation/nearCampsite.png';
import scenicImage from '../assets/icons/standard/scenic.png';
import scenicPrefImage from '../assets/icons/animation/scenic.png';
import cocktailsImage from '../assets/icons/standard/cocktails.png';
import cocktailsPrefImage from '../assets/icons/animation/cocktails.png';
import playAreaImage from '../assets/icons/standard/playArea.png';
import playAreaPrefImage from '../assets/icons/animation/playArea.png';
import functionRoomImage from '../assets/icons/standard/functionRoom.png';
import functionRoomPrefImage from '../assets/icons/animation/functionRoom.png';
import takeawayAvailableImage from '../assets/icons/standard/takeawayAvailable.png';
import takeawayAvailablePrefImage from '../assets/icons/animation/takeawayAvailable.png';
import glutenFreeFoodImage from '../assets/icons/standard/glutenFreeFood.png';
import glutenFreeFoodPrefImage from '../assets/icons/animation/glutenFreeFood.png';
import veganImage from '../assets/icons/standard/vegan.png';
import veganPrefImage from '../assets/icons/animation/vegan.png';
import vegetarianImage from '../assets/icons/standard/vegetarian.png';
import vegetarianPrefImage from '../assets/icons/animation/vegetarian.png';
import nearbyStationImage from '../assets/icons/standard/nearbyStation.png';
import nearbyStationPrefImage from '../assets/icons/animation/nearbyStation.png';
import caskAleImage from '../assets/icons/standard/caskAle.png';
import caskAlePrefImage from '../assets/icons/animation/caskAle.png';
import lgbtqPlusImage from '../assets/icons/standard/lgbtqPlus.png';
import lgbtqPlusPrefImage from '../assets/icons/animation/lgbtqPlus.png';
import studentOffersImage from '../assets/icons/standard/studentOffers.png';
import studentOffersPrefImage from '../assets/icons/animation/studentOffers.png';
import microPubImage from '../assets/icons/standard/microPub.png';
import microPubPrefImage from '../assets/icons/animation/microPub.png';
import happyHourImage from '../assets/icons/standard/happyHour.png';
import happyHourPrefImage from '../assets/icons/animation/happyHour.png';
import boardGamesImage from '../assets/icons/standard/boardGames.png';
import boardGamesPrefImage from '../assets/icons/animation/boardGames.png';

const featureImages = {
    beerGarden: { standard: beerGardenImage, pref: beerGardenPrefImage },
    dogFriendly: { standard: dogFriendlyImage, pref: dogFriendlyPrefImage },
    liveMusic: { standard: liveMusicImage, pref: liveMusicPrefImage },
    poolTable: { standard: poolTableImage, pref: poolTablePrefImage },
    pubFood: { standard: pubFoodImage, pref: pubFoodPrefImage },
    realAle: { standard: realAleImage, pref: realAlePrefImage },
    darts: { standard: dartsImage, pref: dartsPrefImage },
    disabledAccess: { standard: disabledAccessImage, pref: disabledAccessPrefImage },
    pubQuiz: { standard: pubQuizImage, pref: pubQuizPrefImage },
    sundayRoast: { standard: sundayRoastImage, pref: sundayRoastPrefImage },
    btSports: { standard: btSportsImage, pref: btSportsPrefImage },
    skySports: { standard: skySportsImage, pref: skySportsPrefImage },
    parking: { standard: parkingImage, pref: parkingPrefImage },
    accommodation: { standard: accommodationImage, pref: accommodationPrefImage },
    nearCampsite: { standard: nearCampsiteImage, pref: nearCampsitePrefImage },
    scenic: { standard: scenicImage, pref: scenicPrefImage },
    cocktails: { standard: cocktailsImage, pref: cocktailsPrefImage },
    playArea: { standard: playAreaImage, pref: playAreaPrefImage },
    functionRoom: { standard: functionRoomImage, pref: functionRoomPrefImage },
    takeawayAvailable: { standard: takeawayAvailableImage, pref: takeawayAvailablePrefImage },
    glutenFreeFood: { standard: glutenFreeFoodImage, pref: glutenFreeFoodPrefImage },
    vegan: { standard: veganImage, pref: veganPrefImage },
    vegetarian: { standard: vegetarianImage, pref: vegetarianPrefImage },
    nearbyStation: { standard: nearbyStationImage, pref: nearbyStationPrefImage },
    caskAle: { standard: caskAleImage, pref: caskAlePrefImage },
    lgbtqPlus: { standard: lgbtqPlusImage, pref: lgbtqPlusPrefImage },
    studentOffers: { standard: studentOffersImage, pref: studentOffersPrefImage },
    microPub: { standard: microPubImage, pref: microPubPrefImage },
    happyHour: { standard: happyHourImage, pref: happyHourPrefImage },
    boardGames: { standard: boardGamesImage, pref: boardGamesPrefImage },
};

const Favourites = () => {
    const [favorites, setFavorites] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const venuesPerPage = 5;
    const storedUserId = localStorage.getItem('userId');
    const storedUserPreferences = JSON.parse(localStorage.getItem('userPreferences'));

    useEffect(() => {
        const fetchFavorites = async () => {
            setLoading(true);
            try {
                const response = await fetch(`https://api.timeatthebar.co.uk/favourites/${storedUserId}`);
                const data = await response.json();
                setFavorites(data.favourites);
            } catch (error) {
                console.error('Error fetching favorites:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchFavorites();
    }, [storedUserId]);

    const renderFeatureIcons = (venue) => {
        const featureKeys = Object.keys(venue.features);
        return featureKeys.map((key) => {
            if (venue.features[key]) {
                const imageSrc = storedUserPreferences && storedUserPreferences[key] ? featureImages[key].pref : featureImages[key].standard;
                return (
                    <img
                        key={key}
                        src={imageSrc}
                        alt={key}
                        className={`feature-icon ${storedUserPreferences && storedUserPreferences[key] ? 'preference' : ''}`}
                        onError={(e) => { e.target.onerror = null; e.target.src = featureImages[key].standard; }}
                    />
                );
            }
            return null;
        });
    };

    const renderPagination = () => {
        const totalPages = Math.ceil(favorites.length / venuesPerPage);
        return (
            <div className="pagination-container">
                {page > 1 && (
                    <button className="pagination-button" onClick={() => setPage(page - 1)}>
                        <i className="fa fa-chevron-left"></i>
                    </button>
                )}
                <span className="pagination-page">{page}</span>
                {page < totalPages && (
                    <button className="pagination-button" onClick={() => setPage(page + 1)}>
                        <i className="fa fa-chevron-right"></i>
                    </button>
                )}
            </div>
        );
    };

    if (loading) {
        return <Loading />;
    }

    if (favorites.length === 0) {
        return (
            <div className="favourites">
                <h1>Your Favorite Venues</h1>
                <p>You have no favourite venues <span role="img" aria-label="sad face">😢</span></p>
            </div>
        );
    }

    const paginatedFavorites = favorites.slice((page - 1) * venuesPerPage, page * venuesPerPage);

    return (
        <div className="favourites">
            <h1>Your Favorite Venues</h1>
            <div className="venues-list">
                {paginatedFavorites.map((venue) => (
                    <div key={venue._id} className="venue-card">
                        <Link to={`/venue/${venue._id}`}>
                            <img
                                src={venue.images.length > 0 ? `data:image/png;base64,${venue.images[0]}` : defaultImage}
                                alt={venue.title}
                                className="venue-image"
                            />
                        </Link>
                        <div className="venue-info">
                            <h3>{venue.title.replace(/&amp;/g, '&')}</h3>
                            <p>{venue.county}</p>
                            <div className="features">
                                {renderFeatureIcons(venue)}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {renderPagination()}
        </div>
    );
};

export default Favourites;
import React, { useState, useEffect } from 'react';
import './FriendsTab.css';

const FriendsTab = ({ userId }) => {
    const [friends, setFriends] = useState([]);
    const [friendCode, setFriendCode] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [showFriendCode, setShowFriendCode] = useState(false);
    const [venueNames, setVenueNames] = useState({});
    const [venuePostcodes, setVenuePostcodes] = useState({});

    useEffect(() => {
        const fetchFriends = async () => {
            try {
                const response = await fetch('https://api.timeatthebar.co.uk/getFriends', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ userId })
                });
                const data = await response.json();
                setFriends(data.friends);
                const drinkingVenueIds = data.friends.map(friend => friend.drinkingAt).filter(Boolean);
                drinkingVenueIds.forEach(id => fetchVenueName(id));
            } catch (error) {
                console.error('Error fetching friends:', error);
            }
        };

        const fetchVenueName = async (venueId) => {
            try {
                const response = await fetch(`https://api.timeatthebar.co.uk/get-venue/${venueId}`);
                const data = await response.json();
            
                // Ensure title is a string before applying .replace
                const venueTitle = data.venue.title && typeof data.venue.title === 'string'
                    ? data.venue.title.replace(/&amp;/g, '&')
                    : '';  // Provide a default empty string or handle accordingly
            
                setVenueNames(prevNames => ({
                    ...prevNames,
                    [venueId]: venueTitle
                }));
            
                setVenuePostcodes(prevPostcodes => ({
                    ...prevPostcodes,
                    [venueId]: data.venue.postcode
                }));
            } catch (error) {
                console.error('Error fetching venue name:', error);
            }
            
        };

        fetchFriends();
    }, [userId]);

    const handleAddFriend = async () => {
        try {
            const response = await fetch('https://api.timeatthebar.co.uk/addFriend', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userId, friendCode, username, email })
            });
            const data = await response.json();
            if (data.message === 'Friend added successfully') {
                setFriends([...friends, data.friend]);
                setFriendCode('');
                setUsername('');
                setEmail('');
            }
        } catch (error) {
            console.error('Error adding friend:', error);
        }
    };

    const handleRemoveFriend = async (friendId) => {
        try {
            const response = await fetch('https://api.timeatthebar.co.uk/removeFriend', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userId, friendId })
            });
            const data = await response.json();
            if (data.message === 'Friend removed successfully') {
                setFriends(friends.filter(friend => friend._id !== friendId));
            }
        } catch (error) {
            console.error('Error removing friend:', error);
        }
    };

    const fetchFriendCode = async () => {
        try {
            const response = await fetch('https://api.timeatthebar.co.uk/getUser', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userId })
            });
            const data = await response.json();
            setShowFriendCode(data.data.friendCode);
        } catch (error) {
            console.error('Error fetching friend code:', error);
        }
    };

    return (
        <div className="friends-tab">
            <h2>Friends</h2>
            <input
                type="text"
                placeholder="Enter friend code"
                value={friendCode}
                onChange={(e) => setFriendCode(e.target.value)}
                className="friend-code-input"
            />
            <input
                type="text"
                placeholder="Enter username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="username-input"
            />
            <input
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="email-input"
            />
            <button onClick={handleAddFriend} className="add-friend-button">Add Friend</button>
            <button onClick={fetchFriendCode} className="show-friend-code-button">Show Your Friend Code</button>
            {showFriendCode && <div className="friend-code-modal">{showFriendCode}</div>}
            <div className="friends-list">
                {friends.length === 0 ? (
                    <p>
                        Nights out are more fun with friends, add a friend above.
                        <span className="pointing-icons">⬆️⬆️⬆️</span>
                    </p>
                ) : (
                    friends.map(friend => (
                        <div key={friend._id} className="friend-card">
                            <div className="friend-info">
                                <p>{friend.firstName} {friend.lastName}</p>
                                {friend.drinkingAt ? <p>Drinking at: {venueNames[friend.drinkingAt] || 'Loading...'} in {venuePostcodes[friend.drinkingAt] || 'Loading...'}</p> : <p>Not drinking</p>}
                            </div>
                            <button onClick={() => handleRemoveFriend(friend._id)} className="remove-friend-button">Remove</button>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default FriendsTab;

import React from 'react';
import './Home.css';
import { Link, useLocation } from 'react-router-dom';
import FeatureBlock from '../components/FeatureBlock';
import PricingCard from '../components/PricingCard';
import TestimonialCarousel from '../components/TestimonialCarousel';
import CTASection from '../components/CTASection';
import largePhone from '../assets/largePhone.png';

const Home = () => {

    return (
        <div className="home-container">
            <header className="home-header flex">
                <div className='section-left'>
                    <div className='centered-box'>
                        <h1>Unmatched Experience.<br />Effortless Discovery.</h1>
                        <p>Welcome to Time at the Bar. We’re your ultimate guide to the best bars, pubs, and clubs in the UK. Our mission is simple: to help you find your next favorite spot to drink, unwind, and have a great time. Whether you’re looking for a cozy pub down the road or the hottest nightclub in the city, we’ve got you covered.</p>
                        <ul>
                            <li>We believe finding your perfect venue should be quick and easy.</li>
                            <li>We believe in keeping things up-to-date, so you never miss out on the best spots in town.</li>
                            <li>We believe every night out should be unforgettable — and it starts with the right place.</li>
                        </ul>
                        <p className='quote'>The UK’s first and best bar, pub & club finder<br />completely free to use, always.</p>
                        <div className='downloadButtons'>
                        <Link to="/download"><button>Download TAB</button></Link>
                        </div>
                    </div>
                </div>
                <div className="welcome section-right">
                    <img className='largePhone' src={largePhone} alt="Time at the Bar Logo" />
                </div>
            </header>
            <section className="features-section">
                <div className="features-grid">
                    <FeatureBlock
                        icon="fa-beer"
                        title="Venue Directory"
                        body="Explore over 40,000 active venues with bars across the UK."
                    />
                    <FeatureBlock
                        icon="fa-map-marker-alt"
                        title="Location-Based Search"
                        body="Find bars and venues near you with our advanced search."
                    />
                    <FeatureBlock
                        icon="fa-user"
                        title="Join Friends"
                        body="Use our app to connect and view where your friends are drinking."
                    />
                    <FeatureBlock
                        icon="fa-calendar-alt"
                        title="Events"
                        body="Stay updated on the latest events happening at bars near you."
                    />
                    <FeatureBlock
                        icon="fa-thumbs-up"
                        title="Recommendations"
                        body="Get personalized bar recommendations based on your preferences."
                    />
                    <FeatureBlock
                        icon="fa-mobile-alt"
                        title="Mobile Friendly"
                        body="Access our platform on any device, anytime, anywhere."
                    />
                </div>
            </section>
            <section className="testimonials-section">
                <TestimonialCarousel />
            </section>
            <CTASection />
        </div>
    );
};

export default Home;

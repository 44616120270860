import React from 'react';
import './CTASection.css';
import android from '../assets/androidStore.png';
import ios from '../assets/appStore.png';
import web from '../assets/webStore.png';
import largePhoneInverse from '../assets/largePhoneInverse.png';

const CTASection = () => {
    return (
        <section className="cta-section">
            <div className='section-left-inverse'>
                    <img className='largePhoneInverse' src={largePhoneInverse} alt="Time at the Bar Logo" />
            </div>
            <div className='section-right-inverse'>
                <h1>Download TAB</h1>
                <p>Available On:</p>
                <div className='downloadImages'>
                    <img className='downloadImage' src={ios} alt="Time at the Bar Logo" />
                    <img className='downloadImage' src={android} alt="Time at the Bar Logo" />
                    <img className='downloadImage' src={web} alt="Time at the Bar Logo" />
                </div>
            </div>
        </section>
    );
};

export default CTASection;

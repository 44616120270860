import React, { useState } from 'react';
import TestimonialCard from './TestimonialCard';
import './TestimonialCarousel.css';

const TestimonialCarousel = () => {

    const testimonials = [
        { quote: "Time at the Bar has made finding the best pubs in town so easy!", name: "John Doe", position: "Pub Enthusiast" },
        { quote: "The event listings are fantastic. I never miss a night out now.", name: "Jane Smith", position: "Event Organizer" },
        { quote: "User reviews are spot on. Great platform for discovering new spots.", name: "Emily Johnson", position: "Travel Blogger" },
        { quote: "An indispensable tool for pub hopping. Highly recommend it.", name: "Michael Brown", position: "Nightlife Reviewer" },
        { quote: "The best directory for finding local bars. It's super user-friendly.", name: "Lisa White", position: "Bar Owner" },
        { quote: "Excellent recommendations and up-to-date information.", name: "David Wilson", position: "Beer Connoisseur" },
        { quote: "Great platform with a wide range of venues and features.", name: "Sara Lee", position: "Food and Drink Critic" },
        { quote: "A must-have app for anyone who loves exploring pubs.", name: "Chris Green", position: "Lifestyle Journalist" }
    ];

    const visibleTestimonials = testimonials

    return (
        <div className="testimonial-container">
            <div className="testimonial-row">
                {visibleTestimonials.map((testimonial, index) => (
                    <TestimonialCard key={index} {...testimonial} />
                ))}
            </div>
        </div>
    );
};

export default TestimonialCarousel;

import React from 'react';
import './About.css';
import ContactForm from '../components/ContactForm';
import pubImage from '../assets/pubImage.png';

const About = () => {
    return (
        <div className="about-container">
            <div className='flex-container'>
                <div className='section-left'>
                    <header className="about-header">
                        <h1>About Time at the Bar</h1>
                        <p>Welcome to Time at the Bar, your ultimate guide to the best bars and venues in the UK.</p>
                    </header>
                </div>
                <div className='section-right'>
                    <img className='pubImage' src={pubImage} alt="Time at the Bar Logo" />
                </div>
            </div>
            <div className='flex-container-small'>
                <div className="mission">
                    <h2>Our Mission</h2>
                    <p>At Time at the Bar, our mission is to help you discover the best bars and venues across the UK. We aim to provide an easy-to-use platform where users can find and explore venues, read reviews, and stay updated on the latest events.</p>
                </div>
            </div>
            <div className='flex-container-small'>
                <div className="vision">
                    <h2>Our Vision</h2>
                    <p>Our vision is to be the leading directory for bar venues in the UK, continually enhancing our platform based on user feedback. We aim to provide the most comprehensive and up-to-date information, making it easier for users to find their next favorite spot.</p>
                </div>
            </div>
            <div className='flex-container-small'>
                <section className="history">
                    <h2>Our History</h2>
                    <p>Founded in May 2024, Time at the Bar quickly grew to become the largest directory of venues with bars in the UK. With over 40,000 active venues and 20+ features, we are committed to providing a robust and user-friendly platform for all bar enthusiasts.</p>
                </section>
            </div>
            <br></br>
        </div>
    );
};

export default About;
